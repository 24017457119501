import { STEPS } from "../../views/LandingRecipient/components/steps/Steps.interface";
import { Run, RUN_FLAGS, RUN_PROGRESS_STATE } from "../interfaces/run.interface";
import { Address, DELIVERY_TYPE } from "../interfaces/run.interface";

export function getAddressState(run?: Run, step?: Address) {
    const lastDeliveryStep = step?.deliveryStateType
    if (run && step) {
        if (lastDeliveryStep === DELIVERY_TYPE.FINISH) {
            return STEPS.DONE;
        } else if (lastDeliveryStep === DELIVERY_TYPE.CANCEL) {
            return STEPS.IN_PROGRESS;
        } else if (lastDeliveryStep === DELIVERY_TYPE.AT_PLACE) {
            return STEPS.AT_PLACE;
        } else if (lastDeliveryStep === DELIVERY_TYPE.ON_THE_WAY) {
            return STEPS.APPROACHING;
        } else if ((!lastDeliveryStep || lastDeliveryStep == DELIVERY_TYPE.NOT_STARTED) && (run?.progressState === RUN_PROGRESS_STATE.IN_PROGRESS || run?.progressState === RUN_PROGRESS_STATE.STARTED)) {
            return STEPS.IN_PROGRESS;
        } else if ((!lastDeliveryStep || lastDeliveryStep == DELIVERY_TYPE.NOT_STARTED) && run?.flag === RUN_FLAGS.TROOPERS_CHOOSEN) {
            return STEPS.PLANNED;
        } else if ((!lastDeliveryStep || lastDeliveryStep == DELIVERY_TYPE.NOT_STARTED) && run?.flag === RUN_FLAGS.WAITING_FOR_TROOPERS) {
            return STEPS.NOT_PLANNED;
        }
    }
    return STEPS.NONE;
}