import axios from "axios"

let env: any
export async function initEnv() {
    try {
        const resp = await axios.get('/env')
        if (resp.status == 200) {
            env = resp.data
            console.log("Env variables loaded")
        }  
    } catch(err) {
        console.log("No env variables: " + err)
    }
}


export function getEnv() {
    if (env) {
        return env
    }
    return process.env;
}

export default function config() {
    const env = getEnv();
    return {
        service_landing: {
            endpoint: env.REACT_APP_SERVICE_LANDING || 'http://localhost:3016'
        }
    }
}
