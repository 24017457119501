import { Component } from 'react';
import { PROGRESS_CLASS, STEPS, StepsIndicatorProps, StepsIndicatorStates } from './Steps.interface';
import './Steps.css';
export default class StepsIndicator extends Component<StepsIndicatorProps, StepsIndicatorStates> {
    constructor(props: StepsIndicatorProps) {
        super(props);
        this.state = {
        };
    }
    
    getClass(selectState: STEPS) {
        const {currentState} = this.props;
        if (currentState === STEPS.NOT_PLANNED) {
            if (selectState === STEPS.PLANNED) {
                return PROGRESS_CLASS.IS_ACTIVE
            }
        } else if (currentState === STEPS.PLANNED) {
            if (selectState === STEPS.PLANNED) {
                return PROGRESS_CLASS.IS_COMPLETE
            }
        } else if (currentState === STEPS.IN_PROGRESS) {
            if ([STEPS.PLANNED].includes(selectState)) {
                return PROGRESS_CLASS.IS_COMPLETE
            } else if (selectState === STEPS.IN_PROGRESS) {
                return PROGRESS_CLASS.IS_ACTIVE
            }
        } else if (currentState === STEPS.AT_PLACE) {
            if ([STEPS.PLANNED, STEPS.IN_PROGRESS, STEPS.APPROACHING].includes(selectState)) {
                return PROGRESS_CLASS.IS_COMPLETE
            }
        } else if (currentState === STEPS.APPROACHING) {
            if ([STEPS.PLANNED, STEPS.IN_PROGRESS].includes(selectState)) {
                return PROGRESS_CLASS.IS_COMPLETE
            } else if ([STEPS.APPROACHING].includes(selectState)) {
                return PROGRESS_CLASS.IS_ACTIVE
            }
        } else if (currentState === STEPS.DONE) {
            if ([STEPS.PLANNED, STEPS.IN_PROGRESS, STEPS.APPROACHING, STEPS.DONE].includes(selectState)) {
                return PROGRESS_CLASS.IS_COMPLETE
            }
        }
        return '';
    }

    getFull(selectState: STEPS) {
        const {currentState} = this.props;
        if (currentState) {
            if ([STEPS.NOT_PLANNED, STEPS.PLANNED].includes(currentState)) {
                if ([STEPS.PLANNED].includes(selectState)) {
                    return '';
                }
            } else if ([STEPS.IN_PROGRESS].includes(currentState)) {
                if ([STEPS.PLANNED].includes(selectState)) {
                    return 'full';
                }
            } else if ([STEPS.APPROACHING, STEPS.AT_PLACE].includes(currentState)) {
                if ([STEPS.PLANNED, STEPS.IN_PROGRESS].includes(selectState)) {
                    return 'full';
                }
            } else if ([STEPS.DONE].includes(currentState)) {
                if ([STEPS.PLANNED, STEPS.IN_PROGRESS, STEPS.APPROACHING].includes(selectState)) {
                    return 'full';
                }
            }
        }
    }

    render() {
        return (
            <div className="progress">
                <div className="progress-track"></div>
                <div id={STEPS.PLANNED} className={`progress-step ${this.getClass(STEPS.PLANNED)} ${this.getFull(STEPS.PLANNED)}`}>
                    Planifiée
                </div>
                <div id={STEPS.IN_PROGRESS} className={`progress-step ${this.getClass(STEPS.IN_PROGRESS)} ${this.getFull(STEPS.IN_PROGRESS)}`}>
                    En cours
                </div>
                <div id={STEPS.APPROACHING} className={`progress-step ${this.getClass(STEPS.APPROACHING)} ${this.getFull(STEPS.APPROACHING)}`}>
                    En approche
                </div>
                <div id={STEPS.DONE} className={`progress-step ${this.getClass(STEPS.DONE)}`}>
                    Terminée
                </div>
            </div>
        );
    }
}
