export interface Run {
    id: string;
    flag: RUN_FLAGS;
    date: string;
    companyName?: string | null;
    tms: boolean;
    progressState: RUN_PROGRESS_STATE | null
    troopers: {
        name?: string | null;
        phone?: string | null;
    };
    shipper: {
        name: string;
    },
    viewOnly?: boolean;
    currentStep: Address
    freightForwarderId?: string
}

export interface Address {
    id?: string;
    address?: string;
    timeSlot?: {
        start?: string;
        end?: string;
    };
    name?: string;
    logo?: string;
    shipperId: string;
    deliveryStateType?: DELIVERY_TYPE | null,
    estimatedTimeOfArrival?: string | null
}

export enum DELIVERY_TYPE {
    NOT_STARTED = "notStarted",
    CANCEL = "cancel",
    FINISH = "finish",
    AT_PLACE = "atPlace",
    ON_THE_WAY = "onTheWay"
}

export interface RunProgress {
    state: RUN_PROGRESS_STATE,
    step?: string
}

export enum RUN_PROGRESS_STATE {
    STARTED = "started",
    IN_PROGRESS = "inProgress",
    DONE = "done"
}

export enum RUN_FLAGS {
    DRAFT = 'Brouillon',
    UNTREATED = 'Non traité',
    QUOTE = 'Devis',
    WAITING = 'En attente',
    PAYMENT = 'Lien de paiement',
    WAITING_FOR_TROOPERS = 'Attente de troopers',
    TROOPERS_CHOOSEN = 'Troopers attribué',
    DONE = 'Terminée',
    CANCELED = 'Annulée',
    REBILLING = 'Refacturation',
    REFUNDED = 'Remboursement',
    INSURANCE = 'Assurance',
}

export const previewRun: Run = {
    id: 'preview-id',
    flag: RUN_FLAGS.TROOPERS_CHOOSEN,
    date: new Date().toISOString(),
    companyName: 'Executor Transport',
    tms: false,
    progressState: RUN_PROGRESS_STATE.IN_PROGRESS,
    troopers: {
        name: 'Joe LeCamion',
        phone: "+336 00 00 00 00"
    },
    shipper: {
        name: 'Prévisualisation'
    },
    currentStep: {
        id: 'preview',
        address: "3 rue Castéjà, Boulogne-Billancourt, 92100",
        timeSlot: {
            start: '08:00',
            end: '12:00',
        },
        deliveryStateType: DELIVERY_TYPE.AT_PLACE,
        estimatedTimeOfArrival: '10:20',
        shipperId: "",
    }
}