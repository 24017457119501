import { Run, Address } from "../../../../services/interfaces/run.interface";

export interface StepsIndicatorProps {
    run?: Run,
    currentStep?: Address,
    currentState: STEPS | null
}
export interface StepsIndicatorStates {

}

export enum PROGRESS_CLASS {
    IS_ACTIVE = "is-active",
    IS_COMPLETE = "is-complete",
}

export enum STEPS {
    NONE = "planned",
    NOT_PLANNED = "not_planned",
    PLANNED = "planned",
    IN_PROGRESS = "in_progress",
    APPROACHING = 'approaching',
    AT_PLACE = 'at_place',
    DONE = 'done'
}