import { Component } from 'react';
import { AddressDetailsProps, AddressDetailsStates } from './address-details.interface';
import './address-details.css';
import {DateTime} from 'luxon'

export default class AddressDetails extends Component<AddressDetailsProps, AddressDetailsStates> {
    constructor(props: AddressDetailsProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='address-details-container'>
                {this.props.configurationDisplayableField?.date?.display &&
                    <p>
                        <span className='bold'>Date : </span>
                        {this.props.run?.date &&
                            DateTime.fromISO(this.props.run?.date).toFormat('dd/MM/yyyy')
                        }
                        {!this.props.run?.date &&
                            'Non disponible'
                        }
                    </p>
                }
                {this.props.configurationDisplayableField?.address?.display &&
                    <p>
                        <span className='bold'>Adresse : </span>
                        {this.props.currentStep?.address &&
                            this.props.currentStep?.address
                        }
                        {!this.props.currentStep?.address &&
                            'Non disponible'
                        }
                    </p>
                }
                {this.props.configurationDisplayableField?.customComment?.display &&
                    <p>
                        {this.props.configurationDisplayableField?.customComment?.comment}
                    </p>
                }
            </div>
        );
    }
}
