import { Component } from 'react';
import { LogoCardProps, LogoCardStates } from './LogoCard.interface';
import './LogoCard.css';

export default class LogoCard extends Component<LogoCardProps, LogoCardStates> {
    constructor(props: LogoCardProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='logoCard-container'>
                {this.props.name &&
                    <p>Votre commande {this.props.name}</p>
                }
                {(this.props.logo?.length && this.props.logo?.length>0) ?
                    <div className='logoCard-customer-logo'>
                        <img src={this.props.logo} />
                    </div>
                    :<></>
                }
            </div>
        );
    }
}
