import { Component } from 'react';
import { HeaderProps, HeaderStates } from './Header.interface';
import Logo from '../../../../assets/img/logo.png'
import './Header.css';

export default class Header extends Component<HeaderProps, HeaderStates> {
    constructor(props: HeaderProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <>
                {(!this.props.tms && this.props?.configurationCustomization?.logo?.freightForwarder?.display) &&
                    <div className='header-container'>
                        <img src={Logo} alt="mytroopers logo" />
                    </div>
                }
            </>
        );
    }
}
