import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import { initEnv } from './config/config';
import reportWebVitals from './reportWebVitals';
import LandingRouter from './Router';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
); 
initEnv().then(() => {
  root.render(
      <React.StrictMode>
          <LandingRouter />
      </React.StrictMode>
  );
})

reportWebVitals();
