import { Component } from 'react';
import { RatingInput, RatingProps, RatingStates } from './rating.interface';
import './rating.css';
import axios from 'axios';
import config from '../../../../config/config';

export default class Rating extends Component<RatingProps, RatingStates> {
    constructor(props: RatingProps) {
        super(props);
        this.state = {
            openModalRating: false,
            ratingIsValidate: false,
            errorOnRating: false,
            note: 0,
            rating: {
                punctual: false,
                broken: false,
                cautious: false,
                friendly: false,
                carClean: false,
                otherIssue: {
                    checked: false,
                    text: '',
                },
            }
        };
    }

    resetState() {
        this.setState({
            openModalRating: false,
            rating: {
                punctual: false,
                broken: false,
                cautious: false,
                friendly: false,
                carClean: false,
                otherIssue: {
                    checked: false,
                    text: '',
                },
            }
        })
    }

    async validateRating() {
        this.setState({ errorOnRating: false, ratingIsValidate: false })
        const configuration = config();
        const serviceEndpoint = configuration.service_landing.endpoint;
        const ratingInput: RatingInput = {
            runId: this.props.run?.id || '',
            stepId: this.props.run?.currentStep.id || '',
            note: this.state.note,
            rating: this.state.rating
        }
        const result = await axios.post(
            `${serviceEndpoint}/run/rating/${this.props.secret}`,
            {
                headers: { Accept: 'application/json' },
                body: ratingInput
            }
        ).catch((err) => this.setState({ errorOnRating: true }));
        if (result) {
            this.resetState()
            this.setState({ ratingIsValidate: true })
        }
        return;
    }

    render() {
        return (
            <>
                <div className='block-rating'>
                    {this.state.ratingIsValidate &&
                        <p>Merci d'avoir noté votre commande effectuée par {this.props.run?.troopers?.name}</p>
                    }
                    {!this.state.ratingIsValidate &&
                        <>
                            <p>Merci de noter votre commande effectuée par {this.props.run?.troopers?.name}</p>
                            <fieldset className="rating">
                                <input type="radio" id="star5" name="rating" value="5" onClick={() => this.setState({ note: 5 })} />
                                <label className="full" htmlFor="star5" title="Super - 5 stars"></label>

                                <input type="radio" id="star4half" name="rating" value="4 and a half" onClick={() => this.setState({ note: 4.5 })} />
                                <label className="half" htmlFor="star4half" title="Très bien - 4.5 stars"></label>

                                <input type="radio" id="star4" name="rating" value="4" onClick={() => this.setState({ note: 4 })} />
                                <label className="full" htmlFor="star4" title="Très bien - 4 stars"></label>

                                <input type="radio" id="star3half" name="rating" value="3 and a half" onClick={() => this.setState({ note: 3.5 })} />
                                <label className="half" htmlFor="star3half" title="Correcte - 3.5 stars"></label>

                                <input type="radio" id="star3" name="rating" value="3" onClick={() => this.setState({ note: 3 })} />
                                <label className="full" htmlFor="star3" title="Correcte - 3 stars"></label>

                                <input type="radio" id="star2half" name="rating" value="2 and a half" onClick={() => this.setState({ note: 2.5 })} />
                                <label className="half" htmlFor="star2half" title="Pas super - 2.5 stars"></label>

                                <input type="radio" id="star2" name="rating" value="2" onClick={() => this.setState({ note: 2 })} />
                                <label className="full" htmlFor="star2" title="Pas super - 2 stars"></label>

                                <input type="radio" id="star1half" name="rating" value="1 and a half" onClick={() => this.setState({ note: 1.5 })} />
                                <label className="half" htmlFor="star1half" title="Non satisfait - 1.5 stars"></label>

                                <input type="radio" id="star1" name="rating" value="1" onClick={() => this.setState({ note: 1 })} />
                                <label className="full" htmlFor="star1" title="Horrible - 1 star"></label>

                            </fieldset>

                            <button
                                id='button-validate-rating'
                                className='button'
                                style={{ marginBottom: '10px', color: this.state.note ? undefined : 'lightgray', cursor: this.state.note ? undefined : 'not-allowed' }}
                                disabled={!this.state.note}
                                onClick={() => {
                                    if (this.state.note) {
                                        if (this.state.note < 5) {
                                            this.setState({ openModalRating: true })
                                        } else {
                                            return this.validateRating()
                                        }
                                    }
                                }}
                            >
                                Valider
                            </button>
                            {this.state.errorOnRating &&
                                <span style={{ color: 'red', margin: '10px', fontSize: '14px' }}>
                                    Une erreur s'est produite, merci de réessayer.
                                </span>
                            }
                        </>
                    }
                </div>
                {this.state.openModalRating &&
                    <div className='modal-rating'>
                        <p>Quelle est la raison de votre note ?</p>
                        <div className='modal-rating-reason'>
                            <button
                                id="button-punctual"
                                className={`button ${this.state.rating.punctual ? 'light' : 'light-grey'}`}
                                onClick={() => this.setState({ rating: { ...this.state.rating, punctual: !this.state.rating.punctual } })}
                            >
                                Retard
                            </button>
                            <button
                                id="button-cautious"
                                className={`button ${this.state.rating.cautious ? 'light' : 'light-grey'}`}
                                onClick={() => this.setState({ rating: { ...this.state.rating, cautious: !this.state.rating.cautious } })}
                            >
                                Qualité de travail
                            </button>
                            <button
                                id="button-friendly"
                                className={`button ${this.state.rating.friendly ? 'light' : 'light-grey'}`}
                                onClick={() => this.setState({ rating: { ...this.state.rating, friendly: !this.state.rating.friendly } })}
                            >
                                Sympathie
                            </button>
                            <button
                                id="button-broken"
                                className={`button ${this.state.rating.broken ? 'light' : 'light-grey'}`}
                                onClick={() => this.setState({ rating: { ...this.state.rating, broken: !this.state.rating.broken } })}
                            >
                                Casse
                            </button>
                            <button
                                id="button-carClean"
                                className={`button ${this.state.rating.carClean ? 'light' : 'light-grey'}`}
                                onClick={() => this.setState({ rating: { ...this.state.rating, carClean: !this.state.rating.carClean } })}
                            >
                                Propreté utilitaire
                            </button>
                            <button
                                id="button-otherIssue"
                                className={`button ${this.state.rating.otherIssue.checked ? 'light' : 'light-grey'}`}
                                onClick={() => this.setState({
                                    rating: {
                                        ...this.state.rating, otherIssue: {
                                            ...this.state.rating.otherIssue, checked: !this.state.rating.otherIssue.checked
                                        }
                                    }
                                })}
                            >
                                Autre
                            </button>
                            {this.state.rating.otherIssue.checked &&
                                <div className='modal-rating-comment'>
                                    <textarea
                                        id='textarea-otherIssue'
                                        onChange={(elm) => this.setState({
                                            rating: {
                                                ...this.state.rating, otherIssue: {
                                                    ...this.state.rating.otherIssue, text: elm.target.value
                                                }
                                            }
                                        })}
                                    />
                                </div>
                            }
                        </div>
                        <div className='modal-rating-validate'>
                            <button
                                id='button-rating-back'
                                className='button light'
                                onClick={() => this.resetState()}
                            >
                                Retour
                            </button>
                            <button
                                id='button-rating-reason-validate'
                                className='button full'
                                onClick={() => this.validateRating()}
                            >
                                Valider
                            </button>
                        </div>
                    </div>
                }
            </>
        );
    }
}
