import axios from 'axios';
import { Component } from 'react';
import AddressComplementaryDetails from './components/address-complementary-details/address-complementary-details';
import AddressDetails from './components/address-details/address-details';
import Header from './components/header/Header';
import LogoCard from './components/logo-card/LogoCard';
import StepsIndicator from './components/steps/Steps';
import { STEPS } from './components/steps/Steps.interface';
import config from '../../config/config';
import { LANDING_CONFIGURATION_STATUS, RecipientConfiguration } from '../../services/interfaces/landing-configuration.interface';
import { previewRun, Run } from '../../services/interfaces/run.interface';
import { getAddressState } from '../../services/utils/address.utils';
import './Recipient.css';
import { PreviewParams, RecipientProps, RecipientStates } from './Recipient.interface';
import Loader from '../../assets/img/three-dots_black.svg'

export default class Recipient extends Component<RecipientProps, RecipientStates> {
    constructor(props: RecipientProps) {
        super(props);
        this.state = {
            currentState: STEPS.NONE,
            displayLanding: false,
            isLoading: true
        };
    }

    async componentDidMount() {
        const { secret, params } = this.props.match.params;

        if (secret) {
            const run = await this.getRun(secret)
            if (run) {
                const account = await this.getAccount(run.currentStep.shipperId || run.freightForwarderId || "DEFAULT");
                run.currentStep.name = account.name;
                run.currentStep.logo = account.logo;

                let currentState: STEPS | null = STEPS.NONE;
                if (run?.currentStep) {
                    currentState = getAddressState(run, run.currentStep);
                }
                this.setState({ run, currentState, isLoading: false, displayLanding: true }, () => this.getConfigurations());
            }
        }
        if (params) {
            this.setPreviewMod(JSON.parse(decodeURIComponent(params)))
        }
    }

    async componentDidUpdate(prevProps: RecipientProps, prevState: RecipientStates) {
        if (!this.state.previewMod && ["null", null].includes(prevState.currentState) && !["null", null].includes(this.state.currentState)) {
            await this.getConfigurations()
        }
    }

    async setPreviewMod(params: PreviewParams) {
        const account = await this.getAccount(params.shipperId || params.carrierId || params.freightForwarderId);
        const run = previewRun;
        run.currentStep.name = account.name;
        run.currentStep.logo = account.logo;
        this.setState({
            displayLanding: true, isLoading: false, previewMod: true,
            run,
            currentState: params.displayableField.status as unknown as STEPS,
            configurationDisplayableField: params.displayableField,
            configurationCustomization: params.customization
        });
    }

    async getAccount(shipperId: string) {
        this.setState({ isLoading: true })
        const configuration = config();
        const serviceEndpoint = configuration.service_landing.endpoint;
        const accountResponse = await axios.get(`${serviceEndpoint}/recipient/${shipperId}`, { headers: { Accept: 'application/json' } })
            .catch((err) => this.setState({ isLoading: false }));
        //this.setState({ isLoading: false })
        return accountResponse?.data;
    }

    async getRun(secret?: string) {
        this.setState({ isLoading: true })
        const configuration = config();
        const serviceEndpoint = configuration.service_landing.endpoint;
        const runResponse = await axios.get(`${serviceEndpoint}/run/${secret}`, { headers: { Accept: 'application/json' } })
            .catch((err) => this.setState({ isLoading: false }));
        const run: Run = runResponse?.data;

        return run;
    }

    async getConfigurations() {
        const configuration = config();
        const serviceEndpoint = configuration.service_landing.endpoint;
        const recipientResponse = await axios.get(`${serviceEndpoint}/recipient/${this.state.run?.currentStep.shipperId || this.state.run?.freightForwarderId}`, { headers: { Accept: 'application/json' } })
            .catch((err) => this.setState({ isLoading: false }));
        const recipientConfiguration: RecipientConfiguration = recipientResponse?.data
        const currentState = this.state.currentState;
        const configurationCustomization = recipientConfiguration?.customization;
        if (recipientConfiguration && this.state.run?.currentStep?.id && currentState) {
            let configurationDisplayableField = this.getConfigurationDisplayableField(currentState, recipientConfiguration);
            this.setState({ configurationDisplayableField, configurationCustomization });
        }
        this.setState({ isLoading: false })
        return;
    }

    getConfigurationDisplayableField(currentState: STEPS, recipientConfiguration: RecipientConfiguration) {
        console.log("====currentSa", currentState)
        if ([STEPS.NOT_PLANNED, STEPS.PLANNED].includes(currentState)) {
            return recipientConfiguration?.displayableField?.find((conf) => conf.status === LANDING_CONFIGURATION_STATUS.PLANNED)
        } else if ([STEPS.IN_PROGRESS].includes(currentState)) {
            return recipientConfiguration?.displayableField?.find((conf) => conf.status === LANDING_CONFIGURATION_STATUS.IN_PROGRESS)
        } else if ([STEPS.APPROACHING, STEPS.AT_PLACE].includes(currentState)) {
            return recipientConfiguration?.displayableField?.find((conf) => conf.status === LANDING_CONFIGURATION_STATUS.APPROACHING)
        } else if ([STEPS.DONE].includes(currentState)) {
            return recipientConfiguration?.displayableField?.find((conf) => conf.status === LANDING_CONFIGURATION_STATUS.DONE)
        }
        return recipientConfiguration?.displayableField?.find((conf) => conf.status === LANDING_CONFIGURATION_STATUS.PLANNED)
    }

    render() {
        return (
            <div className='landing-container'>
                {this.state.displayLanding &&
                    <>
                        <Header
                            tms={this.state?.run?.freightForwarderId != undefined}
                            configurationCustomization={this.state.configurationCustomization}
                        />
                        <LogoCard
                            name={this.state.run?.currentStep.name}
                            logo={this.state.run?.currentStep.logo}
                        />
                        <StepsIndicator
                            run={this.state.run}
                            currentStep={this.state.run?.currentStep}
                            currentState={this.state.currentState}
                        />
                        <AddressDetails
                            run={this.state.run}
                            currentStep={this.state.run?.currentStep}
                            configurationDisplayableField={this.state.configurationDisplayableField}
                        />
                        <AddressComplementaryDetails
                            secret={this.props.match.params.secret || ''}
                            run={this.state.run}
                            currentStep={this.state.run?.currentStep}
                            configurationDisplayableField={this.state.configurationDisplayableField}
                            configurationCustomization={this.state.configurationCustomization}
                            currentState={this.state.currentState}
                        />
                    </>
                }
                {!this.state.displayLanding &&
                    <>
                        {this.state.isLoading &&
                            <img src={Loader} className={'loader'} />
                        }
                        {!this.state.isLoading &&
                            <div className='error-message'>
                                Cette commande n'est plus disponible
                            </div>
                        }
                    </>
                }
            </div>
        );
    }
}
