export interface RecipientConfiguration {
    shipperId?: string,
    name?: string,
    logo?: string
    displayableField?: RecipientConfigurationDisplayableField[],
    customization?: RecipientConfigurationCustomization
}

export interface RecipientConfigurationCustomization {
    logo?: {
        freightForwarder?: RecipientConfigurationDisplay
        customer?: RecipientConfigurationDisplay
    },
    backgroundColor?: string,
    textColor?: string,
}

export interface RecipientConfigurationDisplayableField {
    status?: LANDING_CONFIGURATION_STATUS
    date?: RecipientConfigurationDisplay,
    address?: RecipientConfigurationDisplay,
    customComment?: RecipientConfigurationDisplayWithComment,
    transporterInfos?: RecipientConfigurationDisplay,
    rating?: RecipientConfigurationDisplay
    timeSlot?: RecipientConfigurationDisplay
    estimatedTimeOfArrival?: RecipientConfigurationDisplay
}

export interface RecipientConfigurationDisplayWithComment extends RecipientConfigurationDisplay {
    comment: string
}
export interface RecipientConfigurationDisplay {
    display: boolean
}

export enum LANDING_CONFIGURATION_STATUS {
    PLANNED = "planned",
    IN_PROGRESS = "in_progress",
    APPROACHING = 'approaching',
    DONE = 'done'
}