import { Component } from "react";
import { Route, BrowserRouter as Router, Switch, RouteComponentProps } from "react-router-dom";
import Recipient from "./views/LandingRecipient/Recipient";

export interface LandingRouterProps { }
export interface LandingRouterStates { }

export default class LandingRouter extends Component<LandingRouterProps, LandingRouterStates> {
    constructor(props: LandingRouterProps) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/preview/:params" render={(props: RouteComponentProps) => (
                        <Recipient {...props} />
                    )} />
                    <Route exact path="/:secret" render={(props: RouteComponentProps) => (
                        <Recipient {...props} />
                    )} />
                </Switch>
            </Router>
        )
    }
}