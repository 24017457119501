import { Component } from 'react';
import { AddressComplementaryDetailsProps, AddressComplementaryDetailsStates } from './address-complementary-details.interface';
import IconPhone from '../../../../assets/img/phone.svg';
import './address-complementary-details.css';
import Rating from '../rating/rating';
import { STEPS } from '../steps/Steps.interface';

export default class AddressComplementaryDetails extends Component<AddressComplementaryDetailsProps, AddressComplementaryDetailsStates> {
    constructor(props: AddressComplementaryDetailsProps) {
        super(props);
        this.state = {};
    }

    getBackgroundColor() {
        if (this.props?.configurationCustomization?.backgroundColor) {
            return this.props?.configurationCustomization?.backgroundColor;
        }
        return 'var(--blue)';
    }

    getTextColor() {
        if (this.props?.configurationCustomization?.textColor) {
            return this.props?.configurationCustomization?.textColor;
        }
        return 'white';
    }

    render() {
        return (
            <div className='address-complementary-details-container' style={{
                backgroundColor: this.getBackgroundColor(),
                color: this.getTextColor()
            }}>
                <div className='block-informations'>
                    {/* ADDRESS timeSlot */}
                    {this.props.currentStep?.timeSlot?.start && this.props?.configurationDisplayableField?.timeSlot?.display &&
                        <div>
                            <p>
                                <span className='bold'>Créneau horaire : </span>
                            </p>
                            <p>
                                {this.props.currentStep?.timeSlot.start} -
                                {this.props.currentStep?.timeSlot.end}
                            </p>
                        </div>
                    }

                    {/* ADDRESS ESTIMATE TIME OF ARRIVAL */}
                    {this.props.configurationDisplayableField?.estimatedTimeOfArrival?.display &&
                        <div>
                            {
                                this.props.currentStep?.estimatedTimeOfArrival && (
                                    <>
                                        <p>
                                            <span className='bold'>Arrivée estimée vers : </span>
                                        </p>
                                        <p>
                                            {this.props.currentStep?.estimatedTimeOfArrival} (environ)
                                        </p>
                                    </>
                                )
                            }
                            {
                                !this.props.currentStep?.estimatedTimeOfArrival && this.props.currentState === STEPS.APPROACHING && (
                                    <p>
                                        Le chauffeur est en approche
                                    </p>
                                )
                            }
                        </div>
                    }
                </div>

                {/* TRANSPORTER INFORMATIONS */}
                {((!this.props.run?.troopers?.name || !this.props?.configurationDisplayableField?.transporterInfos?.display) && !this.props?.configurationDisplayableField?.rating?.display) &&
                    <div className='transporter-informations'>
                        <p>
                            Nous vous communiquerons les coordonnées de votre transporteur dès que possible.
                        </p>
                    </div>
                }
                {this.props.run?.troopers?.name && this.props?.configurationDisplayableField?.transporterInfos?.display &&
                    <div className='transporter-informations'>
                        <p>
                            Votre commande est effectuée par {this.props.run?.troopers?.name}
                        </p>
                        <p>
                            <img src={IconPhone} style={{ color: 'white' }} className="icon" />
                            <a style={{ color: this.getTextColor() }} href={`tel:${this.props.run?.troopers.phone}`}>{this.props.run?.troopers.phone}</a>
                        </p>
                    </div>
                }

                {(this.props?.configurationDisplayableField?.rating?.display && !this.props.run?.viewOnly) &&
                    <Rating
                        secret={this.props.secret}
                        run={this.props.run}
                    />
                }
            </div>
        );
    }
}
